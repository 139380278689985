const requestHandler = function(request) {
  request.startTime = new Date().toISOString();

  return request;
};

const errorHandler = function(error) {
  if (error.response && error.response.config) {
    error.response.config.endTime = new Date().toISOString();
  }

  return Promise.reject(Object.assign({}, error));
};

const successHandler = function(response) {
  response.config.endTime = new Date().toISOString();

  return response;
};

export default {
  requestHandler,
  errorHandler,
  successHandler,
};
