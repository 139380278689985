import httpClient from "@/configs/AxiosConfig";

import { GET_JOB_LIST, GET_JOB_DETAIL } from "@/constant/ApiConstant";

export const getJobList = () =>
  new Promise((resolve, reject) => {
    httpClient
      .get(GET_JOB_LIST)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response));
  });

export const getJobDetail = (id) =>
  new Promise((resolve, reject) => {
    httpClient
      .get(`${GET_JOB_DETAIL}/${id}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response));
  });

export default {
  getJobList,
  getJobDetail,
};
