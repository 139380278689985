<template>
  <div id="career-detail" v-if="detail">
    <page-content class="career-detail-page-header" :title="detail.title" :subtitle="`${detail.department ? detail.department : ''} • ${detail.workTime ? detail.workTime : ''}`"></page-content>

    <section id="career-detail-info" class="pt-0">
      <div class="container">
        <div class="job-item">
          <h5 class="job-title">Job Description</h5>
          <p class="job-text">
            {{ detail.description }}
          </p>
        </div>

        <div v-html="detail.qualification"></div>

        <div class="job-item">
          <a :href="getCareerPath()" target="_blank" rel="noreferrer noopener">
            <b-button variant="primary">Apply Now</b-button>
          </a>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { BButton } from "bootstrap-vue";
import JobService from "@/services/JobService";

import { CAREER_NEW_CANDIDATE_PATH } from "@/constant/CareerConstant";

const PageContent = () => import("@/components/PageContent.vue");

export default {
  name: "CareerDetail",
  components: {
    BButton,
    PageContent,
  },
  data: () => ({
    detail: {},
  }),
  methods: {
    getDetailJob() {
      const id = this.$route.params.id;
      JobService.getJobDetail(id)
        .then((res) => (this.detail = res))
        .catch(() => this.$router.push("/career"));
    },
    getCareerPath() {
      return CAREER_NEW_CANDIDATE_PATH + "/" + this.detail.id + "?pos=" + this.detail.title;
    },
  },
  mounted() {
    this.getDetailJob();
  },
};
</script>
