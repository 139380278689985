import axios from "axios";
import { API_KEY } from "../constant/ApiConstant";

// Handler
import HttpHandler from "../handlers/HttpHandler";

// Create Instance
const AxiosConfig = axios.create({
  baseURL: process.env.VUE_APP_API_PATH,
  timeout: Number(process.env.VUE_APP_REQUEST_TIMEOUT),
  headers: {
    "Content-Type": "application/json",
    API_KEY: API_KEY,
  },
});

// Set Interceptor
AxiosConfig.interceptors.request.use(function(request) {
  return HttpHandler.requestHandler(request);
});

AxiosConfig.interceptors.response.use(
  function(response) {
    return HttpHandler.successHandler(response);
  },
  function(error) {
    return HttpHandler.errorHandler(error);
  }
);

export default AxiosConfig;
